import axios from "axios";
axios.defaults.headers.common['x-header'] = "ZnVjayBldmVyeW9uZSB3aG8gbWFrZXMgeW91IGZlZWwgbGlrZSB5b3UgYXJlbid0IGdvb2QgZW5vdWdo";

export const get = (url, config) => {
    return axios.get(url, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/login";
        } else {
            return Promise.reject(error)
        }
    });
}


export const post = (url, data, config) => {
    return axios.post(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/login";
        } else {
            return Promise.reject(error)
        }
    });
}

export const put = (url, data, config) => {
    return axios.put(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/login";
        } else {
            return Promise.reject(error)
        }
    });
}

export const remove = (url, config) => {
    return axios.delete(url, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/login";
        } else {
            return Promise.reject(error)
        }
    });
}