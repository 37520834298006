import {Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const AuthTabs = () => {

    const Router = useNavigate();
    const Location = useLocation();
    const {pathname} = Location;
    const [activeKey, setActiveKey] = useState(0);

    useEffect(()=>{
        if(pathname){
            const find = TabsItems.find((x)=> x.url === pathname);
            setActiveKey(find.key)
        }
    },[pathname])

    const TabsItems = [
        {
            key: 1,
            label: "ورود",
            url: "/login"
        },
        {
            key: 2,
            label: "ثبت نام",
            url: "/register"
        }
    ];

    const handleTabChange = (x) => {
        const find = TabsItems.find((z)=> z.key === x);
        Router(find.url)
    }

    return (
        <Tabs
            className="auth-tabs"
            activeKey={activeKey}
            onChange={handleTabChange}
            centered
            items={TabsItems}
        />
    )
}

export default AuthTabs;