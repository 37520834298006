import {Badge, Tabs} from "antd";
import {useEffect, useState} from "react";

const AdsTabs = ({userState, getActiveTab}) => {

    const [activeTab, setActiveTab] = useState(null);

    useEffect(()=>{
        getActiveTab(activeTab)
    },[activeTab]);

    const tabs = [
        {
            key: 1,
            label: <>
                <span className={"ms-2"}>همه آگهی ها</span>
                <Badge style={{marginRight: "10px"}} count={userState?.all}/>
            </>,
            value: null
        },
        {
            key: 2,
            label: <>
                <span className={"ms-2"}>تائید شده</span>
                <Badge style={{marginRight: "10px"}} count={userState?.confirmed}/>
            </>,
            value: 1
        },
        {
            key: 3,
            label: <>
                <span className={"ms-2"}>در انتظار تائید</span>
                <Badge style={{marginRight: "10px"}} count={userState?.awaiting}/>
            </>,
            value: 0
        },
        {
            key: 4,
            label: <>
                <span className={"ms-2"}>در حال بررسی</span>
                <Badge style={{marginRight: "10px"}} count={userState?.moderating}/>
            </>,
            value: 2
        },
        {
            key: 5,
            label: <>
                <span className={"ms-2"}>رد شده</span>
                <Badge style={{marginRight: "10px"}} count={userState?.declined}/>
            </>,
            value: 3
        },
        {
            key: 6,
            label: <>
                <span className={"ms-2"}>منقضی</span>
                <Badge style={{marginRight: "10px"}} count={userState?.expired}/>
            </>,
            value: 4
        },
    ];

    const handleActiveTab = (v) => {
        const selectedTab = tabs.find((item) => item.key === v);
        setActiveTab(selectedTab.value)
    }


    return (
        <Tabs defaultActiveKey="1" items={tabs} onChange={handleActiveTab} style={{marginTop: "20px"}} />
    )
}

export default AdsTabs;