
import {post, get, put, remove} from "./curd";
import {BaseApiUrl} from "./setting";
import {
    user_login,
    user_register,
    user_confirm,
    user_update,
    get_seller,
    get_seller_ads_by_id,
    get_seller_ads,
    get_seller_refresh,
    post_seller_ads,
    post_seller_ads_option,
    put_seller_ads,
    get_ads_by_id,
    delete_ads_by_id,
    delete_ads_img_by_id,
    put_seller_ads_option,
    upload_video,
    get_seller_notification,
    delete_video,
    post_promote,
    post_payment_confirm,
    get_user_payments,
    get_province,
    get_city_by_province,
    get_district_by_city,
    get_category,
    get_sub_category,
    get_sub_subcat,
    get_brand,
    get_model,
    get_message,
    update_message,
    post_ticket,
    get_ticket,
    get_single_ticket,
    post_ticket_message,
    get_ticket_messages,
    update_ticket_status
} from "./routes";

/* Common */

export const getProvinceApi = () => {
    let url = get_province;
    return get(BaseApiUrl + url);
}

export const getCityByProvinceApi = (id) => {
    let url = get_city_by_province(id);
    return get(BaseApiUrl + url);
}

export const getDistrictByIdApi = (id) => {
    let url = get_district_by_city(id);
    return get(BaseApiUrl + url);
}

export const getCategoryApi = () => {
    let url = get_category;
    return get(BaseApiUrl + url);
}

export const getSubCategoryApi = (id) => {
    let url = get_sub_category(id);
    return get(BaseApiUrl + url);
}

export const getSubSubCategoryApi = (id) => {
    let url = get_sub_subcat(id);
    return get(BaseApiUrl + url);
}

export const getBrandApi = (id) => {
    let url = get_brand(id);
    return get(BaseApiUrl + url);
}

export const getModelApi = (id) => {
    let url = get_model(id);
    return get(BaseApiUrl + url);
}

/* Auth */

export const postSellerLoginApi = (data) => {
    let url = user_login;
    return post(BaseApiUrl + url, data);
}

export const postSellerRegisterApi = (data) => {
    let url = user_register;
    return post(BaseApiUrl + url, data);
}

export const postSellerConfirmApi = (data) => {
    let url = user_confirm;
    return post(BaseApiUrl + url, data);
}

export const postSellerUpdateApi = (data, token) => {
    let url = user_update;
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}


/* Seller */

export const getSellerApi = (token) => {
    let url = get_seller;
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getSellerAdsByIdApi = (id, data) => {
    let url = get_seller_ads_by_id(id);
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url);
}

export const getSellerAdsApi = (data, token) => {
    let url = get_seller_ads;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getSellerAdsRefresh = (id, token) => {
    let url = get_seller_refresh(id);
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const postSellerAdsApi = (data, token) => {
    let url = post_seller_ads;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const postSellerAdsOptionApi = (data, token) => {
    let url = post_seller_ads_option;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const putSellerAdsApi = (id, data, token) => {
    let url = put_seller_ads(id);
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getAdsByIdApi = (id, token) => {
    let url = get_ads_by_id(id);
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const deleteAdsByIdApi = (id, data, token) => {
    let url = delete_ads_by_id(id);
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return remove(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const deleteAdsImageByIdApi = (id, data, token) => {
    let url = delete_ads_img_by_id(id);
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const putSellerAdsOptionApi = (id, data, token) => {
    let url = put_seller_ads_option(id);
    return put(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const uploadVideoApi = (data, token, config) => {
    let url = upload_video;
    return post(BaseApiUrl + url, data, config);
}

export const sellerNotificationApi = (token) => {
    let url = get_seller_notification;
    return get(BaseApiUrl + url,{headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const deleteVideoByIdApi = (id, token) => {
    let url = delete_video(id);
    return remove(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

/* Promote */

export const postPromoteAdsApi = (data, token) => {
    let url = post_promote;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const postPaymentConfirmApi = (data, token) => {
    let url = post_payment_confirm;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}
export const getUserPaymentsApi = (data, token) => {
    let url = get_user_payments;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

/* Message */

export const getMessageApi = (data, token) => {
    let url = get_message;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const updateMessageApi = (id, data, token) => {
    let url = update_message;
    return put(BaseApiUrl + url + `/${id}`, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

/* Ticket */

export const postTicketApi = (data, token) => {
    let url = post_ticket;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getTicketApi = (data, token) => {
    let url = get_ticket;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(BaseApiUrl + url, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getSingleTicketApi = (id, token) => {
    let url = get_single_ticket;
    return get(BaseApiUrl + url + `/${id}`, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const postTicketMessageApi = (data, token) => {
    let url = post_ticket_message;
    return post(BaseApiUrl + url, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const getTicketMessageApi = (id, token) => {
    let url = get_ticket_messages;
    return get(BaseApiUrl + url + `/${id}`, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}

export const setTicketStatusApi = (data, token, id) => {
    let url = update_ticket_status;
    return put(BaseApiUrl + url + `/${id}`, data, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }});
}