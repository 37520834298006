import Scripts from "./scripts";
import { Tag } from 'antd';
import { CheckCircleOutlined, SyncOutlined, ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const Helper = {
    AdsStatus(status) {
        let result = null;

        if(status == 0){
            result = <Tag icon={<SyncOutlined spin />} color="processing">در انتظار تائید</Tag>;
        } else if(status == 1) {
            result = <Tag icon={<CheckCircleOutlined />} color="success">تائید شده</Tag>;
        } else if(status == 2 || status == 6 || status == 7){
            result = <Tag icon={<ClockCircleOutlined spin />} color="processing">در حال بررسی</Tag>;
        } else if(status == 3){
            result = <Tag icon={<CloseCircleOutlined  />} color="error">تایید نشد</Tag>;
        } else if(status == 4){
            result =  <Tag icon={<ExclamationCircleOutlined />} color="warning">منقضی شده</Tag>;
        }

        return result;
    },
    AdsPrice(price, pricetype){
        let result = null;
        if(price && pricetype === 1){
            result = <span>تماس بگیرید</span>;
        } else if(price && pricetype == 2) {
            result = <bdi>{Scripts.formatPrice(price)} <span className={"font-size-12"}>تومان</span></bdi>;
        } else {
            result = <span>تماس بگیرید</span>;
        }
        return result;
    },
    AdsType(type){
        let result = null;
        if(type === 1){
            result = {label: 'نو', value: type};
        } else if(type == 2) {
            result = {label: 'کارکرده', value: type};
        } else if(type == 3) {
            result = {label: 'خدماتی', value: type};
        }
        return result;
    },
    AdsCarYear(filter, id){
        let year = [];
        let i,j = 0;

        for(i=1403,j=2024;i>=1370,j>=1991;i--,j--){
            year.push({label: `${i} شمسی /${j} میلادی `, value: `${i},${j}`})
        }

        if(filter){
            return year.find(item => item.value === id);
        } else {
            return year;
        }
    },
    AdsCarColor(filter, id){
        let color = [
            {label: 'سفید', value: '1', color: '#fff'},
            {label: 'مشکی', value: '2', color: '#000'},
            {label: 'نقره ای', value: '3', color: '#C0C0C0'},
            {label: 'نوک مدادی', value: '4', color: '#2e2e2e'},
            {label: 'خاکستری', value: '5', color: '#808080'},
            {label: 'بژ', value: '6', color: '#F5F5DC'},
            {label: 'قهوه ای', value: '7', color: '#8B4513'},
            {label: 'یشمی', value: '8', color: '#006400'},
            {label: 'سرمه ای', value: '9', color: '#00008B'},
            {label: 'آلبالویی', value: '10', color: '#DC143C'},
            {label: 'قرمز', value: '11', color: '#FF0000'},
            {label: 'آبی', value: '12', color: '#1E90FF'},
            {label: 'زرد', value: '13', color: '#FFFF00'},
            {label: 'بادمجانی', value: '14', color: '#4B0082'},
            {label: 'نارنجی', value: '15', color: '#FFA500'},
            {label: 'سبز', value: '16', color: '#008000'},
        ];

        if(filter){
            return color.find(item => item.value === id);
        } else {
            return color;
        }
    },
    AdsEstateOptions(filter, id){
        let options = [
            {label: 'پارکینگ', value: 1},
            {label: 'انباری', value: 2},
            {label: 'آسانسور', value: 3}
        ];

        if(filter){
            let array = id.split(',');
            return array.map(x => options.find(item => item.value === x))
        } else {
            return options;
        }
    },
    AdsSpecial(t){
        let specialAds = null;

        if(t === 1){
            specialAds = <span className="SpecialAds">ویترین</span>;
        } else if(t === 2){
            specialAds = <span className="SpecialAdsFast">فوری</span>;
        } else if(t === 3){
            specialAds = <span className="LinkedAds">لینک</span>;
        } else {
            specialAds = '';
        }

        return specialAds;
    },
    TicketStatus(s){
        let status = null;

        if(s === 1){
            status = <Tag color="processing">در انتظار بررسی</Tag>
        } else if (s === 2){
            status = <Tag color="warning">پاسخ پشتیبانی</Tag>
        } else if (s === 3) {
            status = <Tag color="processing">پاسخ کاربر</Tag>
        } else if (s === 4) {
            status = <Tag color="error">بی نتیجه</Tag>
        } else if (s === 5) {
            status = <Tag color="success">حل شده</Tag>
        } else if (s === 0) {
            status = <Tag color="default">بسته شده</Tag>
        }
        return status;
    },
    TicketDepartment(d){
        let dep = null;

        if(d === 1){
            dep = "پشتیبانی"
        } else {
            dep = "سایر"
        }

        return dep;
    },
    MessageType(t){
        let type = null;
        if(t === 1){
            type = <Tag color="warning">اصلاحیه</Tag>
        } else if(t === 2){
            type = <Tag color="error">اخطاریه</Tag>
        } else if(t === 3){
            type = <Tag color="processing">اعلامیه</Tag>
        }
        return type;
    }
}

export const SellerGender = [
    {label: 'آقا هستم', value: 1},
    {label: 'خانم هستم', value: 2},
];

export default Helper;