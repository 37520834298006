import React, {useState, useEffect, useRef, useMemo} from "react";
import { MapContainer, TileLayer, useMap, useMapEvents, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import marker from '../../assets/img/marker.svg';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import {useDispatch, useSelector} from "react-redux";
import {setLatLong} from "../../redux/actions/user";
import L from 'leaflet';

const Map = () =>{

    const state = useSelector((state)=>state);
    const auth = state.auth;
    const latlng = auth.latlng;
    const dispatch = useDispatch();
    const [position, setPosition] = useState({lat: 35.699739, lng: 51.338097});
    const [searchLayer, setSearchLayer] = useState(false);
    const provider = new OpenStreetMapProvider();
    const mapRef = useRef(null);

    useEffect(()=>{
        if(latlng){
            setPosition(latlng)
        }
    },[latlng]);

    useEffect(()=>{
        setSearchLayer(true);
    },[]);

    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                }
            },
        }),
        [],
    )

    const mapIcon = new L.Icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
    });

    const MapLayer = () => {
        useMapEvents({
            click(e) {
                setPosition(e.latlng)
                storeCords(e.latlng)
            }
        });
        return null;
    }

    const Search = () => {
        const map = useMap();
        if(!searchLayer){
            const searchControl = new GeoSearchControl({
                provider: provider,
                showMarker: false,
                searchLabel: 'جستجو',
                updateMap: true,
            });
            map.removeControl(searchControl);
            map.addControl(searchControl);
        }

        return null;
    }

    const Recenter = ({center}) => {
        const map = useMap();
        map.setView(center);
        return null;
    }

    const storeCords = (pos) => {
        dispatch(setLatLong(pos))
    }

    return (
        <>
            {position ?
                <MapContainer
                    center={position}
                    zoom={14}
                    scrollWheelZoom={false}
                    id={'maps'}
                    ref={mapRef}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://nobazaar.ir">nobazaar.ir</a>'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapLayer/>
                    <Marker
                        icon={mapIcon}
                        draggable={true}
                        eventHandlers={eventHandlers}
                        position={position}
                        ref={markerRef}>
                    </Marker>
                    <Search/>
                    <Recenter center={position} />
                </MapContainer>
                : "y"}
        </>
    )
}

export default Map;