import React, {useEffect, useState} from "react";
import {
    getSingleTicketApi,
    getTicketMessageApi,
    postTicketMessageApi,
    setTicketStatusApi
} from "../../api/api";
import {useSelector} from "react-redux";
import Helper from "../../api/helper";
import Moment from "react-moment";
import 'moment/locale/fa';
import { Button, Input, Spin, Card, Avatar } from 'antd';
import {useNavigate, useParams} from "react-router-dom";
import SellerLayout from "../../layout/seller";
import AdsStyle from "../../assets/css/ads.module.css";
import {
    UserOutlined,
    ClockCircleOutlined,
    SafetyCertificateOutlined
} from '@ant-design/icons';
import {BaseUrl} from "../../api/setting";
const { Meta } = Card;

const TicketView = () => {

    const Router = useNavigate()
    const Param = useParams();
    const { ticket_id } = Param;
    const token = useSelector((state)=>state.auth.token);
    const [Ticket, setTicket] = useState(null);
    const [description, setDescription] = useState("");
    const [messages, setMessages] = useState([]);
    const [ticketLoading, setTicketLoading] = useState(false);
    const [replyLoading, setReplyLoading] = useState(false);

    useEffect(()=>{
        if(ticket_id){
            getTicket(ticket_id, token);
        }
    },[ticket_id]);

    useEffect(()=>{
        document.title = "نوبازار - تیکت ها"
    },[]);

    const getTicket = async (id, t) => {
        setTicketLoading(true);
        await getSingleTicketApi(id, t).then((r)=>{
            setTicket(r.data);
            getTicketMessages(r.data.id);
            setTimeout(()=>{
                setTicketLoading(false);
            },1000)
        }).catch(()=>{
            Router('/ticket')
        })
    }

    const handleCreateTicket = async () => {

        const data = {
            description: description,
            ticket_id: Ticket.id
        }

        await postTicketMessageApi(data, token).then((r)=>{
            setDescription(null);
            getTicketMessages(Ticket.id, token);
            getTicket(Ticket.id, token);
            handleUpdateTicket(3);
        })
    }

    const handleUpdateTicket = async (s) => {
        const data = {
            status: s
        }
        await setTicketStatusApi(data, token, ticket_id).then(()=>{
            if(s === 0){
                Router('/ticket')
            }
        })
    }

    const getTicketMessages = async (id) => {
        setReplyLoading(true);

        await getTicketMessageApi(id, token).then((r)=>{
            setMessages(r.data)
            setTimeout(()=>{
                setReplyLoading(false);
            },1000)
        })
    }

    return (
        <SellerLayout>
            <div className={AdsStyle.AdsWrapper}>
                {Ticket ?
                    <>
                        <div className="d-flex justify-content-between" style={{marginBottom: "25px"}}>
                            <div>
                                <strong>موضوع: {Ticket?.subject}</strong>
                                <Moment style={{marginRight: "10px"}} element={'time'} locale={'fa'} fromNow>{Ticket.created_at}</Moment>
                            </div>

                            <div>
                                <span>دپارتمان: {Helper.TicketDepartment(Ticket?.department)}</span>
                                <bdi style={{marginRight: "10px", display: "inline-block"}}>{Helper.TicketStatus(Ticket?.status)}</bdi>
                            </div>
                        </div>

                        <Card
                            style={{marginBottom: "10px"}}
                            actions={[

                            ]}
                            loading={ticketLoading}>
                            <Meta
                                avatar={<Avatar size={40} src={`${BaseUrl}/images/avatar/${Ticket?.seller?.pic ? Ticket.seller.pic : 0}.png`} />}
                                title={[
                                    <div key={1}>
                                        <UserOutlined />
                                        <span style={{marginRight: "10px", display: "inline-block"}}>{Ticket?.seller?.name} {Ticket?.seller?.lastname}</span>
                                    </div>
                                ]}
                                description={[
                                    <div key={1}>
                                        <ClockCircleOutlined />
                                        <Moment
                                            style={{marginRight: "5px", display: "inline-block"}}
                                            element={'time'}
                                            locale={'fa'}
                                            fromNow>{Ticket.created_at}</Moment>
                                    </div>
                                ]}
                            />
                            <p>{Ticket?.description}</p>
                        </Card>

                        <ul className="list-unstyled" style={{margin: "0px", padding: "0px"}}>
                            {messages ? messages.map((Reply, Index)=> {
                                let admin = Reply.user_id === 91;
                                return (
                                    <Card
                                        style={{marginBottom: "10px"}}
                                        key={Index}
                                        loading={replyLoading}>
                                        <Meta
                                            avatar={<Avatar size={40} src={`${BaseUrl}/images/avatar/${Ticket?.seller?.pic ? Ticket.seller.pic : 0}.png`} />}
                                            title={[
                                                <div key={1}>
                                                    {admin ?
                                                        <>
                                                            <SafetyCertificateOutlined />
                                                            <span style={{marginRight: "10px", display: "inline-block"}}>پشتیبانی</span>
                                                        </>
                                                        :
                                                        <>
                                                            <UserOutlined />
                                                            <span style={{marginRight: "10px", display: "inline-block"}}>{Ticket?.seller?.name} {Ticket?.seller?.lastname}</span>
                                                        </>
                                                    }
                                                </div>
                                            ]}
                                            description={[
                                                <div key={1}>
                                                    <ClockCircleOutlined />
                                                    <Moment
                                                        style={{marginRight: "5px", display: "inline-block"}}
                                                        element={'time'}
                                                        locale={'fa'}
                                                        fromNow>{Reply.created_at}</Moment>
                                                </div>
                                            ]}
                                        />
                                        <p>{Reply?.description}</p>

                                    </Card>
                                )
                            }) : null}
                        </ul>
                    </>
                    : <Spin />}

                {Ticket?.status !=  0 ?
                    <div>
                        <div style={{marginTop: "15px"}}>
                            <Input.TextArea
                                style={{height: "150px"}}
                                value={description}
                                onChange={(e)=>setDescription(e.target.value)}/>
                        </div>
                        <div style={{marginTop: "15px"}}>
                            <Button
                                size="large"
                                disabled={!description ? true : false}
                                type={"primary"}
                                onClick={()=>handleCreateTicket()}>ارسال پاسخ</Button>
                            {Ticket?.status >  0 ?
                                <Button
                                    style={{marginRight: "10px"}}
                                    size="large"
                                    danger
                                    onClick={()=>handleUpdateTicket(0)}>بستن تیکت</Button> : null}
                        </div>
                    </div> : null}
            </div>
        </SellerLayout>
    )
}

export default TicketView;