import {Link} from "react-router-dom";
import logoType from "../../../assets/img/logo_dark.svg";
import React from "react";

const AuthHeader = () => {
    return (
        <header>
            <figure className="d-flex align-items-center justify-content-center">
                <Link to="/">
                    <img width="100" height="58" alt="" src={logoType} className="logotype"/>
                </Link>
            </figure>
        </header>
    )
}

export default AuthHeader;