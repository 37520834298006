import React, {useEffect, useState} from "react";
import SellerLayout from "../../layout/seller";
import styles from "../../assets/css/Seller.module.css";
import {useDispatch, useSelector} from "react-redux";
import {Col, message, Row, Input, Select, Switch, Button, Modal, Avatar, Space} from "antd";
import {SellerGender} from "../../api/helper";
import {postSellerUpdateApi} from "../../api/api";
import {
    CloseOutlined,
    CheckOutlined
} from '@ant-design/icons';
import {BaseUrl} from "../../api/setting";
import {setUserData} from "../../redux/actions/user";
import AdsStyle from "../../assets/css/ads.module.css";

const Profile = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [avatar, setAvatar] = useState(0);
    const token = useSelector((state)=>state.auth.token) || null;
    const sellerRedux = useSelector((state)=>state.auth.user) || {};
    const [seller, setSeller] = useState({});

    useEffect(()=>{
        document.title = "نوبازار - ویرایش پروفایل"
    },[])

    useEffect(()=>{
        if(sellerRedux){
            setSeller(sellerRedux)
        }
    },[sellerRedux])

    const handleUpdateUser = async () => {
        setLoading(true);
        await postSellerUpdateApi(seller, token).then(()=>{
            dispatch(setUserData(seller))
            message.success('اطلاعات با موفقیت بروز شد');
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
        });
    }

    return (
        <SellerLayout>
            <div className={AdsStyle.AdsWrapper}>
                <div className={"avatar profile-avatar m-3 cursor-pointer"} onClick={()=>setModal(true)}>
                    <Avatar size={100} src={`${BaseUrl}/images/avatar/${seller?.pic ? seller.pic : 0}.png`} />

                </div>
                <Row gutter={[10, 10]}>
                    <Col md={8} span={24}>
                        <div>
                            <label className={styles.Hint}>نام</label>
                            <Input value={seller?.name}
                                   onChange={(e)=>
                                       setSeller({...seller, name: e.target.value})}/>
                        </div>
                    </Col>
                    <Col md={8} span={24}>
                        <div>
                            <label className={styles.Hint}>نام خانوادگی</label>
                            <Input value={seller?.lastname}
                                   onChange={(e)=>
                                       setSeller({...seller, lastname: e.target.value})}/>
                        </div>
                    </Col>
                    <Col md={8} span={24}>
                        <div>
                            <label className={styles.Hint}>تلفن</label>
                            <Input value={seller?.tel}
                                   disabled={true}/>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div>
                            <label className={styles.Hint}>جنسیت</label>
                            <Select
                                value={seller?.gender}
                                placeholder={'انتخاب کنید'}
                                showSearch={false}
                                options={SellerGender}
                                onChange={(choice)=>setSeller({...seller, gender: choice})}
                                className="w-100"
                            />
                            <small className={styles.Hint}>توضیح: جنسیت شما برای دیگران نمایش داده نخواهد شد.</small>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div>
                            <label className={styles.Hint}>کد ملی</label>
                            <Input value={seller?.meli_code}
                                   maxLength={10}
                                   onChange={(e)=>
                                       setSeller({...seller, meli_code: e.target.value})}/>
                            <small className={styles.Hint}>برای دریافت تیک آبی نیاز به درج کد ملی می باشد.</small>
                        </div>
                    </Col>
                    <Col md={24}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={seller?.public ? true : false}
                            onChange={(v)=>
                                setSeller({...seller, public: v})}
                        />
                        <label className={styles.Hint}>پروفایل عمومی</label>
                        <small style={{display: "block"}} className={styles.Hint}>در پروفایل عمومی نام و نام خانوادگی و همچنین تمام آگهی های شما در دسترس عموم خواهد بود.</small>
                    </Col>
                </Row>

                <div style={{marginTop: "15px"}}>
                    <Button
                        size="large"
                        loading={loading}
                        onClick={()=> handleUpdateUser()}
                        type="primary">
                        بروزرسانی
                    </Button>
                </div>
            </div>
            <Modal
                centered
                open={modal}
                onCancel={()=>setModal(false)}
                cancelText="بستن"
                okText="انتخاب"
                onOk={()=> (setSeller({...seller, pic: avatar}), setModal(false))}
                okButtonProps={{size: "large"}}
                cancelButtonProps={{size: "large"}}
            >
                <Space size={15} wrap>
                    {Array(15)
                        .fill()
                        .map((_, i) => (
                            <div
                                key={i + 1}
                                onClick={() => setAvatar(i + 1)}
                            >
                                <Avatar
                                    className={`avatar ${avatar === i + 1 ? "active" : ""}`}
                                    size={100}
                                    src={`${BaseUrl}/images/avatar/${i + 1}.png`}
                                />
                            </div>
                        ))}
                </Space>
            </Modal>
        </SellerLayout>
    )
}

export default Profile;