import React from "react";

const AuthFooter = () => {
    return (
        <footer>
            <div style={{marginTop: "30px", fontSize: "10px"}}
                 className="d-flex align-items-center justify-content-center gray-color">تمامی حقوق متعلق به نوبازار می باشد</div>
        </footer>
    )
}

export default AuthFooter;