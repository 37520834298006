import SellerLayout from "../../layout/seller";
import React, {useEffect, useState} from "react";
import {getMessageApi, updateMessageApi} from "../../api/api";
import {useSelector} from "react-redux";
import Moment from "react-moment";
import 'moment/locale/fa';
import Helper from "../../api/helper";
import { Table, Tag, Button } from 'antd';
import {Link} from "react-router-dom";
import AdsStyle from "../../assets/css/ads.module.css";

const Messages = () => {

    const token = useSelector((state)=>state.auth.token) || null;
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    useEffect(()=>{
        getMessage(1)
        document.title = "نوبازار - پیام ها"
    },[])

    const getMessage = async (p) => {
        setLoading(true);
        const data = {
            page: p ? p : 1,
        }
        await getMessageApi(data, token).then((r)=>{
            let data = r.data;

            const direct = r.data.data;
            const global = r.data.global;

            direct.filter((Value, Index)=>{
                Value.key = Value.id;
                Value.dataIndex = Value.id;
            })

            global.filter((Value, Index)=>{
                Value.key = Value.id;
                Value.dataIndex = Value.id;
            })

            const merge = direct.concat(global);

            setMessages({...data, data: merge});
            setLoading(false);
        })
    }

    const handleUpdate = async (v) => {
        let id = v.toString();
        if(id){
            const data = {
                status: 1
            }
            await updateMessageApi(id, data, token)
        }
    }

    const columns = [
        {
            title: 'موضوع',
            dataIndex: 'subject',
            key: 'subject',
            render: (subject, _value) => (_value.status == 0 ? <strong>{subject}</strong> : <span>{subject}</span>)
        },
        {
            title: 'آگهی',
            dataIndex: 'ads',
            key: 'ads',
            render: (ads) => ads ? <Link target="_blank" to={`/seller/edit/${ads?.id}`}>{ads?.title}</Link> : "-"
        },
        {
            title: 'نوع',
            dataIndex: 'type',
            key: 'type',
            render: (type) => <span>{Helper.MessageType(type)}</span>
        },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <Tag color="default">{status === 0 ? "خوانده نشده" : status === 1 ?  "خوانده شده" : "همگانی"}</Tag>
        },
        {
            title: 'تاریخ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Moment locale={'fa'} fromNow>{created_at}</Moment>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (_, record) => <Button type="primary" onClick={() => handleExpandRow(record.id)}>نمایش</Button>
        }
    ];

    const handleExpandRow = (recordId) => {
        if (expandedRowKeys.includes(recordId)) {
            setExpandedRowKeys(expandedRowKeys.filter((key) => key !== recordId));
        } else {
            handleUpdate(recordId)
            setExpandedRowKeys([...expandedRowKeys, recordId]);
        }
    }

    const handlePage = (p) => {
        getMessage(p);
    }

    return (
        <SellerLayout>
            <div className={AdsStyle.AdsWrapper}>
                <div className={"mb-4"}>
                    <h2 className={"font-size-20 fw-bold"}>پیام ها</h2>
                </div>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={messages?.data}
                    scroll={{ x: 400 }}
                    expandable={{
                        expandedRowKeys,
                        expandedRowRender: (record) => (
                            <p>
                                {record.message}
                            </p>
                        ),
                        onExpand: (_, record) => handleExpandRow(record.id),
                    }}
                    pagination={{
                        current: messages.current_page,
                        total: messages.total,
                        onChange: (p) => handlePage(p),
                    }}
                />
            </div>
        </SellerLayout>
    )
}

export default Messages;