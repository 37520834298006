import Styles from "../../assets/css/Seller.module.css";
import SellerHeader from "./parts/header";
import SellerSidebar from "./parts/sidebar";
import SellerFooter from "./parts/footer";
import { Layout } from 'antd';
import {useState} from "react";
const {Content } = Layout;

const SellerLayout = ({children}) => {

    const [collapsed, setCollapsed] = useState(false);
    const getCollapsed = (x) => {
        setCollapsed(x)
    }

    return (
        <Layout hasSider>
            <SellerSidebar getCollapsed={getCollapsed}/>
            <Content className={Styles.MainContent} style={{marginRight: collapsed ? "80px" : "250px"}}>
                <SellerHeader/>
                <main className={Styles.Main}>
                    {children}
                </main>
                <SellerFooter/>
            </Content>
        </Layout>
    )
}

export default SellerLayout;