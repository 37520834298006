import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Ads from "../../components/browse/ads";
import {handleAddToBookmark} from "../../api/common";
import SellerLayout from "../../layout/seller";
import {Row, Col, Alert} from "antd";

const Bookmark = () => {

    const token = useSelector((state)=>state.auth.token) || null;
    const bookmark = useSelector((state)=>state.global?.bookmark) || [];
    const dispatch = useDispatch();
    const [bookmarks, setBookmarks] = useState([]);

    useEffect(()=>{
        getBookmarkAds()
        document.title = "نوبازار - ذخیره شده ها"
    },[]);

    const getBookmarkAds = async () => {
        let array = [];

        try {
            for (let id of bookmark) {
                //const response = await getAdByIdApi(id, token);
                //array.push(response.data);
            }

            setBookmarks(array);
        } catch (error) {
            console.log(error);
        }
    }

    const handleGetAdId = (id, type) => {
        if(type === "display"){
            //dispatch(setQuickView({display: true, id: id}))
        } else if(type === "bookmark") {
            handleAddToBookmark(id, bookmark, dispatch);
        }
    }

    return (
        <SellerLayout>
            <Row>
                {bookmarks?.length ? bookmarks.map((Ad, Index)=>{
                        return (
                            <Col key={Index} xxl={3} xl={4} lg={6} md={12} xs={12}>
                                <Ads
                                    ad={Ad}
                                    getAdId={handleGetAdId}
                                    style={"mb-3"}
                                />
                            </Col>
                        )
                    }) :
                    <Alert type="warning" message="هیچ آگهی وجود ندارد" className="w-100"/>}
            </Row>
        </SellerLayout>
    )
}

export default Bookmark;