import React, {useEffect, useState} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {Avatar, Tag, Layout, Menu} from "antd";
import {useDispatch, useSelector} from "react-redux";
import Styles from '../../../assets/css/Seller.module.css';
import {useWindowSize} from "../../../components/windowSize";

import {
    ShopOutlined,
    AppstoreOutlined,
    UserOutlined,
    MessageOutlined,
    MailOutlined,
    SwapOutlined,
    UserSwitchOutlined,
    PaperClipOutlined,
    PlusOutlined,
    DashboardOutlined,
    SolutionOutlined,
    LinkOutlined
} from '@ant-design/icons';
import {setLogOut} from "../../../redux/actions/user";
import {BaseUrl, domain} from "../../../api/setting";
const { Sider } = Layout;

const SellerSidebar = ({getCollapsed}) => {
    const Router = useNavigate();
    const location = useLocation();
    const {pathname} = location;
    const dispatch = useDispatch();
    const seller = useSelector((state)=> state.auth?.user) || null;
    const states = useSelector((state)=> state.auth?.state) || null;
    const bookmark = useSelector((state)=> state.global?.bookmark)?.length || 0;
    const [collapsed, setCollapsed] = useState(true);
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const {device} = useWindowSize();

    useEffect(()=>{
        if(!seller){
            handleLogOut();
        }
    },[])

    useEffect(()=> {
        getCollapsed(collapsed)
    },[collapsed]);

    useEffect(()=> {
        if(device !== "mobile"){
            setCollapsed(false)
        } else {
            setCollapsed(true)
        }
    },[device]);

    useEffect(()=>{
        if(pathname){
            const find = items.find(item => item.urls && item.urls.some(url => pathname.includes(url)));

            if(find){
                if(find.key === 8){
                    setSelectedKeys([String(8)])
                } else {
                    setOpenKeys([String(find.key)])
                    find?.children?.map((Child)=>{
                        if(Child.url === pathname){
                            setSelectedKeys([String(Child.key)])
                        }
                    })
                }
            }
        }
    },[pathname])

    const handleLogOut = () => {
        dispatch(setLogOut(null))
        Router('/login');
    }

    const items = [
        {
            key: 1,
            label: "خانه",
            icon: <ShopOutlined style={{fontSize: "18px"}}/>,
            onClick: () => window.location.href = domain
        },
        {
            key: 2,
            label: "داشبورد",
            icon: <DashboardOutlined style={{fontSize: "18px"}} />,
            urls: ['/dashboard', '/ads/new', '/ads/bookmark', '/ads/edit/'],
            children: [
                {
                    key: 3,
                    label: <>آگهی های من<Tag style={{marginRight: "10px"}} color={"#f55"}>{states?.ads?.all ? states?.ads?.all : 0}</Tag></>,
                    icon: <AppstoreOutlined style={{fontSize: "18px"}} />,
                    url: "/dashboard",
                    onClick: ()=> Router("/dashboard")
                },
                {
                    key: 4,
                    label: "درج آگهی جدید",
                    icon: <PlusOutlined style={{fontSize: "18px"}} />,
                    url: "/ads/new",
                    onClick: () => Router("/ads/new")
                },
                {
                    key: 5,
                    label: "ذخیره شده ها",
                    icon: <PaperClipOutlined style={{fontSize: "18px"}} />,
                    url: "/ads/bookmark",
                    onClick: () => Router("/ads/bookmark")
                }
            ]
        },
        {
            key: 6,
            label: "حساب کاربری",
            icon: <UserOutlined style={{fontSize: "18px"}} />,
            urls: ['/profile/edit'],
            children: [
                {
                    key: 7,
                    label: "ویرایش اطلاعات",
                    icon: <SolutionOutlined style={{fontSize: "18px"}} />,
                    url: "/profile/edit",
                    onClick: () => Router("/profile/edit")
                },
                seller?.public ? {
                    key: 12,
                    label: <Link target={"_blank"} to={`${domain}/seller/${seller?.id}`}>پروفایل عمومی</Link>,
                    icon: <LinkOutlined style={{fontSize: "18px"}} />
                } : null
            ]
        },
        {
            key: 8,
            label: <>پیام ها<Tag style={{marginRight: "10px"}} color={"#f55"}>{states?.notification?.messages ? states?.notification?.messages : 0}</Tag></>,
            icon: <MailOutlined style={{fontSize: "18px"}} />,
            urls: ['/messages'],
            onClick: ()=> Router("/messages")
        },
        {
            key: 9,
            label: <>پشتیبانی<Tag style={{marginRight: "10px"}} color={"#f55"}>{states?.notification?.tickets ? states?.notification?.tickets : 0}</Tag></>,
            icon: <MessageOutlined style={{fontSize: "18px"}} />,
            urls: ['/ticket'],
            onClick: ()=> Router("/ticket")
        },
        {
            key: 10,
            label: "پرداخت ها",
            icon: <SwapOutlined style={{fontSize: "18px"}} />,
            onClick: ()=> Router("/payments")
        },
        {
            key: 11,
            label: "خروج",
            icon: <UserSwitchOutlined style={{fontSize: "18px"}} />,
            style: {color: "#f55"},
            onClick: () => handleLogOut()
        }
    ]

    const handleOpenChange = (keys) => {
        setOpenKeys(keys);
    }

    const handleMenuSelect = ({ item, key }) => {
        setSelectedKeys([key]);
    }

    return (
        <Sider
            width="250"
            collapsedWidth="80"
            theme="dark"
            className={`${Styles.Sidebar} custom-scroll`}
            collapsible={device === "mobile" ? false : true}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}>
            <div className="d-flex justify-content-center" style={{margin: "10px 0"}}>
                <Link to={"/profile/edit"}>
                    <Avatar size={collapsed ? 50 : 70} src={`${BaseUrl}/images/avatar/${seller?.pic ? seller.pic : 0}.png`} />
                </Link>
            </div>
            {!collapsed ?
                <div className={Styles.SidebarUser}>
                    <strong>{seller?.name} {seller?.lastname}</strong>
                </div> : null}
            <Menu
                openKeys={!collapsed ? openKeys : undefined}
                onOpenChange={handleOpenChange}
                selectedKeys={selectedKeys}
                onSelect={handleMenuSelect}
                className={Styles.SideBarMenu}
                theme="dark"
                mode="inline"
                items={items}
            />
        </Sider>
    )
}

export default SellerSidebar;