/* Seller ApIs */

export const get_seller = "seller/index";
export const get_seller_notification = "seller/notification";
export const get_seller_ads = "seller/ads";
export const get_seller_refresh = (id) => `seller/refresh/${id}`;
export const post_seller_ads = "create/ads";
export const post_seller_ads_option = "create/ads_option";
export const get_seller_ads_by_id = (id) => `ads/seller/${id}`;
export const put_seller_ads = (id) => `update/ads/${id}`;
export const put_seller_ads_option = (id) => `update/ads_option/${id}`;
export const get_ads_by_id = (id) => `edit/ads/${id}`;
export const delete_ads_by_id = (id) => `destroy/ads/${id}`;
export const delete_ads_img_by_id = (id) => `destroy/img/ads/${id}`;
export const upload_video = "seller/video";
export const delete_video = (id) => `destroy/video/ads/${id}`;
export const user_login = "seller/login";
export const user_register = "seller/register";
export const user_confirm = "seller/confirm";
export const user_update = "seller/update";

/* Promote */

export const post_promote = "promote/ads";
export const post_payment_confirm = "payment/confirm";
export const get_user_payments = "seller/payments";

/* Common */

export const get_province = "province/index";
export const get_city_by_province = (id) => `city/province/${id}`;
export const get_district_by_city = (id) => `district/city/${id}`;
export const get_category = `category/index`;
export const get_sub_category = (id) => `category/sub/${id}`;
export const get_sub_subcat = (id) => `subcat/sub/${id}`;
export const get_brand = (id) => `subcat/brand/${id}`;
export const get_model = (id) => `brand/model/${id}`;

/* Message */

export const get_message = "message/index";
export const update_message = "message/update";

/* Ticket */

export const post_ticket = "ticket/create";
export const get_ticket = "ticket/index";
export const get_single_ticket = "ticket/view";
export const get_ticket_messages = "ticket_message";
export const post_ticket_message = "ticket_message/create";
export const update_ticket_status = "ticket/update";