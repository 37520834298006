import {
    SET_LAT_LNG,
    SET_LOGOUT,
    SET_TOKEN,
    SET_USER_DATA,
    SET_USER_STATE,
    SET_BOOKMARK
} from "../constants/user";

export const setUserData = x => ({
    type: SET_USER_DATA,
    payload: x
});

export const setToken = x => ({
    type: SET_TOKEN,
    payload: x
});

export const setUserState = x => ({
    type: SET_USER_STATE,
    payload: x
});

export const setLogOut = x => ({
    type: SET_LOGOUT,
    payload: x
});

export const setLatLong = x => ({
    type: SET_LAT_LNG,
    payload: x
});

export const setAdsBookmark = x => ({
    type: SET_BOOKMARK,
    payload: x
});