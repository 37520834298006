import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {sellerNotificationApi} from "../../../api/api";
import {Avatar, Badge, Dropdown, Layout} from 'antd';
import {MailOutlined, MessageOutlined, UserOutlined, UserSwitchOutlined} from '@ant-design/icons';
import logoType from "../../../assets/img/logo_dark.svg";
import styles from "../../../assets/css/Seller.module.css";
import {setLogOut, setUserState} from "../../../redux/actions/user";
import {BaseUrl} from "../../../api/setting";
import {useWindowSize} from "../../../components/windowSize";
const { Header} = Layout;

const SellerHeader = () => {

    const dispatch = useDispatch();
    const seller = useSelector((state)=> state.auth.user) || null;
    const token = useSelector((state)=> state.auth.token) || null;
    const Router = useNavigate();
    const {device} = useWindowSize();

    useEffect(()=>{
        getNotification();
    },[]);

    const getNotification = async () => {
        await sellerNotificationApi(token).then((r)=>{
            dispatch(setUserState(r.data));
        }).catch((err)=>{
            console.log(err)
        })
    }

    const items = [
        {
            key: 1,
            label: (
                <Link to={"/profile/edit"}>
                    حساب کاربری
                </Link>
            ),
            icon: <UserOutlined/>
        },
        {
            key: 2,
            label: (
                <Link to={"/messages"}>
                    پیام ها
                </Link>
            ),
            icon: <MailOutlined />
        },
        {
            key: 3,
            label: (
                <Link to={"/ticket"}>
                    پشتیبانی
                </Link>
            ),
            icon: <MessageOutlined />
        },
        {
            key: 4,
            danger: true,
            label: <span onClick={()=>handleLogOut()}>خروج</span>,
            icon: <UserSwitchOutlined />
        },
    ];

    const handleLogOut = () => {
        dispatch(setLogOut(null))
        Router('/login');
    }

    return (
        <Header style={{backgroundColor:"white"}}>
            <div className="d-flex justify-content-between align-items-center">
                <div className={styles.SellerLogo}>
                    <img alt={''} width={device === "mobile" ? 60 : 70} height={device === "mobile" ? 30 : 40} src={logoType} className={'logotype'}/>
                </div>

                <div className="d-flex align-items-center">
                    {device != "mobile" ?
                    <>
                        <Link to={"/messages"}>
                            <Badge dot>
                                <MailOutlined style={{fontSize: "20px"}} />
                            </Badge>
                        </Link>
                        <Link to={"/ticket"} style={{margin: "0 20px"}}>
                            <Badge dot>
                                <MessageOutlined style={{fontSize: "20px"}} />
                            </Badge>
                        </Link>
                    </> : null
                    }
                    <Dropdown
                        trigger={['click']}
                        menu={{
                            items,
                        }}
                    >
                        <div>
                            <Avatar size={40} src={`${BaseUrl}/images/avatar/${seller?.pic ? seller.pic : 0}.png`}/>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </Header>
    )
}

export default SellerHeader;