import React from 'react';
import { ConfigProvider, theme } from 'antd';
import faIR from 'antd/lib/locale/fa_IR';

const { defaultAlgorithm } = theme;

const AntProvider = ({ children }) => {


    return (
        <ConfigProvider
            direction="rtl"
            locale={faIR}
            theme={{
                algorithm: defaultAlgorithm,
                token:
                    {
                        fontFamily: "IranSans",
                        colorPrimary: "#f55"
                    }
            }}
        >
            {children}
        </ConfigProvider>
    )
}

export default AntProvider;