import React, {useState, useRef, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setToken, setUserData} from "../../redux/actions/user";
import {postSellerConfirmApi, postSellerLoginApi} from "../../api/api";
import Style from "../../assets/css/Seller.module.css"
import { Button, Input, message, Col, Row } from 'antd';
import { ClockCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import AuthLayout from "../../layout/auth";
import LoginSteps from "../../layout/auth/components/loginSteps";

const Login = () => {
    const Router = useNavigate();
    const dispatch = useDispatch();
    const [formInput, setFormInput] = useState({});
    const [code, setCode] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const textInput1 = useRef(null);
    const textInput2 = useRef(null);
    const textInput3 = useRef(null);
    const textInput4 = useRef(null);

    const [initialTime, setInitialTime] = useState(0);
    const [startTimer, setStartTimer] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);


    useEffect(() => {
        if (initialTime > 0) {
            setTimeout(() => {
                setInitialTime(initialTime - 1);
            }, 1000);
        }

        if (initialTime === 0 && startTimer) {
            setStartTimer(false);
        }

    }, [initialTime, startTimer]);


    useEffect(()=>{
        if(formInput.otp1 && formInput.otp2 && formInput.otp3 && formInput.otp4){
            setCode(`${formInput.otp1}${formInput.otp2}${formInput.otp3}${formInput.otp4}`)
            handleConfirm(`${formInput.otp1}${formInput.otp2}${formInput.otp3}${formInput.otp4}`)
        }
    },[formInput]);

    useEffect(()=> {
       document.title = "نوبازار - ورود"
    },[])

    const handleLogin = async() => {
        setProcessing(true)

        await postSellerLoginApi(formInput).then(()=>{
            message.success("کد تائید با موفقیت ارسال شد");
            setProcessing(false);
            setConfirm(true);
            setInitialTime(60);
            setCurrentStep(1)

        }).catch(()=>{
            message.warning("کاربر یافت نشد!");
            setProcessing(false)
        });
    }

    const handleConfirm = async(code) => {
        setProcessing(true)

        const data = {
            tel: formInput.tel,
            code: code
        }

        await postSellerConfirmApi(data).then((confirm)=>{
            setProcessing(false);
            message.success("با موفقیت وارد شدید");
            Router('/dashboard');
            dispatch(setToken(confirm.data.token));
            dispatch(setUserData(confirm.data.user));
        }).catch(()=>{
            message.error("کد وارد شده صحیح نمی باشد")
            setProcessing(false);
            setCode(null)
            setFormInput({...formInput, otp1: null, otp2: null, otp3: null, otp4: null})
            textInput1.current.focus();
            textInput1.current.select();
        });
    }

    const handleNextInput = (otp, e) => {
        let value = e.target.value;

        if (otp === 'otp1' && value.length === 1) {
            textInput2.current.focus();
            textInput2.current.select();
        } else if(otp === 'otp2' && value.length === 1){
            textInput3.current.focus();
            textInput3.current.select();
        } else if(otp === 'otp3' && value.length === 1){
            textInput4.current.focus();
            textInput4.current.select();
        }
    }

    const handleEnter= (e, fn) => {
        let keyCode = e.which || e.key;
        if(keyCode === 13 || keyCode === 'Enter'){
            fn();
        }
    }

    return (
        <AuthLayout>
            <div className="d-flex align-items-center">
                <ArrowLeftOutlined style={{ fontSize: '26px', marginLeft: '10px' }} />
                <h1 className={Style.AuthTitle}>ورود</h1>
            </div>
            <LoginSteps current={currentStep}/>
            {confirm ?
                <div key={2}>
                    <Row gutter={10} style={{marginTop: "30px"}}>
                        <Col span={6}>
                            <Input
                                value={formInput?.otp4}
                                type="text"
                                tabIndex={4}
                                ref={textInput4}
                                className="text-center"
                                onKeyUp={(e)=>handleNextInput('otp4', e)}
                                onChange={(e)=>setFormInput({...formInput, otp4: e.target.value})}
                                maxLength={1}
                                disabled={!formInput.otp3}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                value={formInput?.otp3}
                                type="text"
                                tabIndex={3}
                                ref={textInput3}
                                className="text-center"
                                onKeyUp={(e)=>handleNextInput('otp3', e)}
                                onChange={(e)=>setFormInput({...formInput, otp3: e.target.value})}
                                maxLength={1}
                                disabled={!formInput.otp2}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                value={formInput?.otp2}
                                type="text"
                                tabIndex={2}
                                ref={textInput2}
                                className="text-center"
                                onKeyUp={(e)=>handleNextInput('otp2', e)}
                                onChange={(e)=>setFormInput({...formInput, otp2: e.target.value})}
                                maxLength={1}
                                disabled={!formInput.otp1}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                value={formInput?.otp1}
                                type="text"
                                tabIndex={1}
                                ref={textInput1}
                                className="text-center"
                                onKeyUp={(e)=>handleNextInput('otp1', e)}
                                onChange={(e)=>setFormInput({...formInput, otp1: e.target.value})}
                                maxLength={1}
                            />
                        </Col>
                        <Col span={24}>
                            <small className={Style.Hint} style={{marginTop: "10px", display: "block"}}>کد تائید ارسال شده به شماره {formInput?.tel} را وارد نمائید</small>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "30px"}}>
                        <Col span={24}>
                            <Button
                                size="large"
                                type="primary"
                                danger
                                loading={processing}
                                disabled={!formInput.otp1 || !formInput.otp1 || !formInput.otp2 || !formInput.otp3 || !formInput.otp4}
                                className="w-100"
                                onClick={()=>handleConfirm(code)}>
                                <span>تائید</span>
                            </Button>
                            <Button
                                style={{marginTop: "10px"}}
                                disabled={initialTime > 0 ? true : false}
                                className="w-100"
                                onClick={()=> (setConfirm(false), setCurrentStep(0))}>
                                {initialTime ? <ClockCircleOutlined spin/> : null}
                                <span>ارسال مجدد</span>
                                <bdi style={{marginRight: "5px"}}>{initialTime > 0 ? initialTime : null}</bdi>
                            </Button>
                        </Col>
                    </Row>
                </div>
                :
                <div key={1}>
                    <Row style={{marginTop: "30px"}}>
                        <Col span={24}>
                            <Input
                                value={formInput.tel}
                                onKeyPress={(e)=>handleEnter(e, handleLogin)}
                                className="letter-space dir-ltr text-center p-2"
                                type="number"
                                placeholder="09---------"
                                onChange={(e)=>setFormInput({...formInput, tel: e.target.value})}
                                maxLength={11}
                                required
                            />
                        </Col>
                        <Col span={24}>
                            <Button
                                size="large"
                                style={{marginTop: "10px"}}
                                type="primary"
                                danger
                                disabled={!formInput.tel || formInput.tel.length < 11}
                                loading={processing}
                                className="w-100 mt-3 btn-line-success"
                                onClick={()=>handleLogin()}>
                                <span>ورود</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
        </AuthLayout>
    )
}

export default Login;