import React, {useEffect, useState} from "react";
import {getSellerAdsApi, getTicketApi, postTicketApi} from "../../api/api";
import {useSelector} from "react-redux";
import Moment from "react-moment";
import 'moment/locale/fa';
import Helper from "../../api/helper";
import { Table, Modal, Button, Input, message } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import {filterOption} from "../../api/common";
import SellerLayout from "../../layout/seller";
import {Link, useNavigate} from "react-router-dom";
import AdsStyle from "../../assets/css/ads.module.css";
import { PlusOutlined } from '@ant-design/icons';

const Ticket = () => {

    const Router = useNavigate();
    const token = useSelector((state)=>state.auth.token) || null;
    const [ticket, setTicket] = useState({department: 1});
    const [ads, setAds] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        getTickets(1);
        getSellerAds();

        document.title = "نوبازار - تیکت ها"
    },[]);

    const getSellerAds  = async() => {
        await getSellerAdsApi(null, token).then((ads) => {
            let data = ads.data;
            data.filter((x)=> (x.value = x.id, x.label = x.title))
            setAds(data);
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleCreateTicket = async () => {
        await postTicketApi(ticket, token).then(()=>{
            setModal(false);
            getTickets(1);
            message.success("تیکت با موفقیت ثبت شد")
            setTicket({})
        })
    }

    const getTickets = async (p) => {
        setLoading(true);
        const data = {
            page: p ? p : 1,
        }
        await getTicketApi(data, token).then((r)=>{
            setTickets(r.data);
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            setLoading(false);
        })
    }

    const handlePage = (p) => {
        getTickets(p);
    }

    const columns = [
        {
            title: 'موضوع',
            dataIndex: 'subject',
            key: 'subject',
            render: (subject, _value) => <Link to={`/ticket/${_value.id}`}>{subject}</Link>
        },
        {
            title: 'مرتبط به آگهی',
            dataIndex: 'ads',
            key: 'ads',
            render: (ads) => ads?.id ? <Link to={`/ads/edit/${ads?.id}`}>{ads?.title}</Link> : "-"
        },
        {
            title: 'شماره تیکت',
            dataIndex: 'ticke_id',
            key: 'ticke_id'
        },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
            render: (status) => Helper.TicketStatus(status)
        },
        {
            title: 'تاریخ ایجاد',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (updated_at) => <Moment locale={'fa'} fromNow>{updated_at}</Moment>
        },
        {
            title: 'تاریخ بروزرسانی',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Moment locale={'fa'} fromNow>{created_at}</Moment>
        },
        {
            title: '',
            dataIndex: 'tools',
            key: 'tools',
            render: (_,_value) => <Button onClick={()=>Router(`/ticket/${_value.id}`)} icon={<EyeOutlined />} type="primary"/>
        }
    ];

    return (
        <SellerLayout>
            <div className={AdsStyle.AdsWrapper}>
                <div className={"d-flex justify-content-between my-4"}>
                    <h2 className={"font-size-20 fw-bold"}>تیکت ها</h2>
                    <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        size="large"
                        onClick={()=>setModal(true)}
                    >
                        <span className={"me-2"}>تیکت جدید</span>
                    </Button>
                </div>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={tickets?.data}
                    scroll={{ x: 400 }}
                    pagination={{
                        current: tickets.current_page,
                        total: tickets.total,
                        onChange: (p) => handlePage(p),
                    }}
                />
            </div>
            <Modal
                centered
                open={modal}
                title="تیکت جدید"
                onCancel={()=>setModal(false)}
                footer={[
                    <Button size="large" key={1} type="primary" disabled={!ticket.subject || !ticket.description}
                            onClick={()=>handleCreateTicket()}>
                        ثبت تیکت
                    </Button>,
                    <Button size="large" key={2} onClick={()=>setModal(false)}>
                        بستن
                    </Button>
                ]}>
                <div style={{marginBottom: "10px"}}>
                    <label style={{marginBottom: "10px", display: "block"}}>
                        عنوان
                    </label>
                    <Input value={ticket.subject} onChange={(e)=>setTicket({...ticket, subject: e.target.value})}/>
                </div>
                <div style={{marginBottom: "10px"}}>
                    <label style={{marginBottom: "10px", display: "block"}}>
                        دپارتمان
                    </label>
                    <Select
                        value={ticket.department}
                        className={"w-100"}
                        placeholder={'انتخاب کنید'}
                        options={[{label: "پشتیبانی", value: 1}]}
                        onChange={(choice)=>setTicket({...ticket, department: choice})}
                    />
                </div>
                <div style={{marginBottom: "10px"}}>
                    <label style={{margin: "10px 0", display: "block"}}>
                        اگر تیکت شما مرتبط به یک آگهی است آن را انتخاب کنید
                    </label>
                    <Select
                        value={ticket.ads_id}
                        className="w-100"
                        placeholder="انتخاب کنید"
                        options={ads}
                        showSearch
                        filterOption={filterOption}
                        onChange={(choice)=>setTicket({...ticket, ads_id: choice})}
                    />
                </div>
                <div>
                    <label style={{marginBottom: "10px", display: "block"}}>
                        متن پیام
                    </label>
                    <Input.TextArea
                        value={ticket.description}
                        onChange={(e)=>setTicket({...ticket, description: e.target.value})}
                    />
                </div>
            </Modal>

        </SellerLayout>
    )
}

export default Ticket;