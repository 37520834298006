import React, {useEffect, useState} from 'react';
import AuthLayout from "../../layout/auth";
import styles from "../../assets/css/Seller.module.css";
import {useNavigate} from "react-router-dom";
import {postSellerRegisterApi} from "../../api/api";
import {message, Row, Col, Input, Button, Select, InputNumber} from "antd";
import {Link} from "react-router-dom";
import Style from "../../assets/css/Seller.module.css";
import {ArrowUpOutlined} from "@ant-design/icons";

const Register = () => {

    const Router = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [formInputs, setFormInputs] = useState({});

    useEffect(()=>{
        getGeo();
        document.title = "نوبازار - ثبت نام"
    },[]);


    const handleRegister = async() => {
        setProcessing(true)

        const data = formInputs;

        await postSellerRegisterApi(data).then(()=>{
            message.success("عضویت با موفقیت انجام شد")
            setProcessing(false)
        }).catch(()=>{
            setProcessing(false)
            message.error("کاربری با این شماره قبلا ثبت نام کرده")
        });
    }

    const getGeo = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setFormInputs({...formInputs, latlong: `${position.coords.latitude},${position.coords.longitude}`})
                },
                (error) => {

                }
            )
        }
    }

    return (
        <AuthLayout>
            <div className="d-flex">
                <ArrowUpOutlined style={{ fontSize: '26px', marginLeft: '10px' }} />
                <h1 className={Style.AuthTitle}>ثبت نام</h1>
            </div>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <label className={styles.Required}>مشخصات</label>

                </Col>
                <Col span="12">
                    <Input
                        type="text"
                        placeholder="نام"
                        onChange={(e) =>
                            setFormInputs({ ...formInputs, name: e.target.value })
                        }
                        required
                    />
                </Col>
                <Col span="12">
                    <Input
                        type="text"
                        placeholder="نام خانوادگی"
                        onChange={(e) =>
                            setFormInputs({ ...formInputs, lastname: e.target.value })
                        }
                        required
                    />
                </Col>
                <Col span="12">
                    <label style={{marginBottom: "10px", display: "block"}} className={styles.Required}>جنسیت</label>
                    <Select
                        className="w-100"
                        placeholder="انتخاب کنید"
                        showSearch={false}
                        options={[{label: 'آقا هستم', value: 1}, {label: 'خانم هستم', value: 2}]}
                        onChange={(choice) =>
                            setFormInputs({ ...formInputs, gender: choice })
                        }
                    />
                    <div className={styles.Hint}>توضیح: جنسیت شما برای دیگران نمایش داده نخواهد شد.</div>
                </Col>
                <Col span="12">
                    <label style={{marginBottom: "10px", display: "block"}}  className={styles.Required}>نوع کاربری</label>
                    <Select
                        className="w-100"
                        placeholder="انتخاب کنید"
                        showSearch={false}
                        options={[{label: 'شخصی', value: 1}, {label: 'شرکت/فروشگاه', value: 2}]}
                        onChange={(choice) =>
                            setFormInputs({ ...formInputs, user_type: choice })
                        }
                    />
                </Col>
                <Col md={'6'}>
                    <label style={{marginBottom: "10px", display: "block"}}  className={styles.Required}>شماره موبایل</label>
                    <Input
                        type="number"
                        placeholder="09---------"
                        onChange={(e) =>
                            setFormInputs({ ...formInputs, tel: e.target.value })
                        }
                        className="w-100 dir-ltr"
                        required
                    />
                    <div className={styles.Hint}>توضیح: شماره موبایل شما همان نام کاربری شما محسوب خواهد شد، پس از صحت آن اطمینان حاصل کنید.</div>
                </Col>
                <Col span="24">
                    <label style={{marginBottom: "10px", display: "block"}}  className={styles.Required}>پست الکترونیک</label>
                    <Input
                        type="text"
                        onChange={(e) =>
                            setFormInputs({ ...formInputs, mail: e.target.value })
                        }
                        required/>
                </Col>
                <Col span="24">
                    <div className="d-flex align-items-center" style={{marginTop: "10px"}}>
                        <Button
                            size="large"
                            type="primary"
                            danger
                            disabled={!formInputs.name || !formInputs.gender || !formInputs.user_type || !formInputs.tel || !formInputs.mail || (formInputs.tel.length < 11 || formInputs.tel.length > 11 ? true : false)}
                            loading={processing}
                            className="w-100"
                            onClick={()=>handleRegister()}>
                            <span>ثبت نام</span>
                        </Button>
                    </div>
                    <div style={{marginTop: "15px"}} className={Style.Hint}>ثبت نام شما به منظور پذیرفتن قوانین نوبازار می باشد</div>
                    <div className="d-flex">
                        <Link target="_blank" to={"/content/rules"}>قوانین</Link>
                        <span style={{margin: "0 5px"}}></span>
                        <Link target="_blank" to={"/content/privacy"}>حریم خصوصی</Link>
                    </div>
                </Col>
            </Row>
        </AuthLayout>
    )
}

export default Register;