import {notification} from "antd";
import {setAdsBookmark} from "../redux/actions/user";

export const handleAddToBookmark = (id, currentBookmark, dispatch) => {

    let current = [...currentBookmark];

    if(current?.includes(id)){
        let index = current.indexOf(id);
        if (index > -1) {
            current.splice(index, 1);
        }
        notification.success({
            message: "موفق",
            description:  "با موفقیت حذف شد",
            placement: "bottomRight"
        });
    } else {
        current?.push(id)

        notification.success({
            message: "موفق",
            description:  "با موفقیت افزوده شد",
            placement: "bottomRight"
        });
    }

    dispatch(setAdsBookmark(current))
}

export const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());