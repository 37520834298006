import React from "react";
import AuthTabs from "./components/authTabs";
import Style from "../../assets/css/Seller.module.css"
import AuthHeader from "./parts/header";
import AuthFooter from "./parts/footer";

const AuthLayout = ({children}) => {
    return (
        <div className="d-flex flex-wrap h-100-vh">
            <main className="w-100">
                <div className="d-flex align-items-center justify-content-center h-100 flex-wrap">
                    <div className={Style.AuthForm}>
                        <AuthHeader/>
                        <div className="m-auto">
                            <AuthTabs/>
                            {children}
                        </div>
                        <AuthFooter/>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AuthLayout;