import {Result, Button} from "antd";
import {useNavigate} from "react-router-dom";

const FourZeroFour = () => {
    const navigate = useNavigate();
    return (
        <Result
            status="404"
            title="404"
            subTitle="اینجا چیزی برای دیدن نیست!"
            extra={<Button onClick={()=>navigate("/")} type="primary">بازگشت به خانه</Button>}
        />
    )
}

export default FourZeroFour;