import {Link} from "react-router-dom";
import {Layout} from "antd";
const { Footer} = Layout;

const SellerFooter = () => {
    return (
        <Footer className="footer-panel">
            <div>
                <div className={'text-center copyright d-md-block d-none'}>
                    <p className={"font-size-11"}>نوبازار 1402-1396 | ورژن 3</p>
                </div>
            </div>
        </Footer>
    )
}

export default SellerFooter;