import React, {useEffect, useState} from "react";
import {getUserPaymentsApi, postPaymentConfirmApi} from "../../api/api";
import {useSelector} from "react-redux";
import {Table, Tag, Modal} from "antd";
import Moment from "react-moment";
import 'moment/locale/fa';
import Scripts from "../../api/scripts";
import Helper from "../../api/helper";
import {Link, useSearchParams} from "react-router-dom";
import AdsStyle from "../../assets/css/ads.module.css";
import SellerLayout from "../../layout/seller";

const Payments = () => {

    const [searchParams] = useSearchParams();
    const token = useSelector((state)=>state.auth.token);
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [paymentData, setPaymentData] = useState(null);
    const [modal, setModal] = useState(false);

    useEffect(()=> {
        getPayments(1)
        document.title = "نوبازار - پرداخت ها"
    },[]);

    useEffect(() => {
        const queryParams = Object.fromEntries(searchParams);
        if(searchParams){
            if(queryParams.callback === "promote"){
                PaymentConfirm(queryParams);
            }
        }
    },[searchParams]);

    const getPayments = async (p) => {
        setLoading(true)
        const data = {
            page: p ? p : 1,
        }
        await getUserPaymentsApi(data, token).then((r)=>{
            setLoading(false)
            r.data.data.filter((x)=> x.key = x.id)
            setPayments(r.data)
        })
    }

    const columns = [
        {
            title: 'شناسه',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'شناسه مرجع',
            dataIndex: 'reference_id',
            key: 'reference_id'
        },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
            render: (status) =>
                <Tag color={status === 1 ? "success" : "error"}>
                    {status === 1 ? "موفق" : "ناموفق"}
                </Tag>
        },
        {
            title: 'نوع',
            dataIndex: 'type',
            key: 'type',
            render: (type) => Helper.AdsSpecial(type)
        },
        {
            title: 'آگهی',
            dataIndex: 'ads',
            key: 'ads',
            render: (ads) => <Link to={`/seller/edit/${ads?.id}`}>{ads?.title}</Link>
        },
        {
            title: 'مبلغ',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => <span>{Scripts.formatPrice(amount)} <bdi>تومان</bdi></span>
        },
        {
            title: 'تاریخ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Moment locale={'fa'} fromNow>{created_at}</Moment>
        }
    ];

    const handlePage = (p) => {
        getPayments(p)
    }

    const PaymentConfirm = async(q) => {
        const {Authority, amount, status, type, id} = q;
        const data = {
            authority: Authority,
            amount: Number(amount),
            type: Number(type),
            ads_id: Number(id)
        }

        await postPaymentConfirmApi(data, token).then((r)=>{
            if(r.data.code === 100){
                setPaymentData("پرداخت با موفقیت انجام شد")
            } else {
                setPaymentData("پرداخت ناموفق بود")
            }
            setModal(true)
        });
    }

    const handleCloseModal = () => {
        getPayments(1);
        setModal(false)
    }


    return (
        <SellerLayout>
            <div className={AdsStyle.AdsWrapper}>
                <div className={"mb-4"}>
                    <h2 className={"font-size-20 fw-bold"}>پرداخت ها</h2>
                </div>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={payments?.data}
                    scroll={{ x: 400 }}
                    pagination={{
                        current: payments.current_page,
                        total: payments.total,
                        onChange: (p) => handlePage(p),
                    }}
                />

            </div>
            <Modal
                open={modal}
                title={"وضعیت پرداخت"}
                onCancel={()=> handleCloseModal()}
                onOk={()=> handleCloseModal()}
                okText={"باشه"}
                cancelText={"بستن"}
            >
                {paymentData}
            </Modal>
        </SellerLayout>
    )
}

export default Payments;