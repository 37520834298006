import React from 'react';
import './assets/css/common.css';
import './assets/css/line-awesome.min.css';
import {Routes, Route, Navigate} from 'react-router-dom';
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import FourZeroFour from "./pages/common/404";
import Dashboard from "./pages/dashboard";
import NewAds from "./pages/ads";
import Profile from "./pages/profile";
import Messages from "./pages/messages";
import Ticket from "./pages/ticket";
import TicketView from "./pages/ticket/[ticket_id]";
import Payments from "./pages/payment";
import Bookmark from "./pages/bookmark";

function App({pageProps}) {
  return (
      <Routes {...pageProps}>
        <Route path='*' element={<FourZeroFour />} />
        <Route
            path="/"
            element={<Navigate to="/login" replace />}
        />
        <Route path={"login"} element={<Login/>} />
        <Route path={"register"} element={<Register/>} />

        <Route path={"dashboard"} element={<Dashboard/>} />
        <Route path={"/ads/new"} element={<NewAds/>} />
        <Route path={"/ads/edit/:id"} element={<NewAds/>} />

        <Route path={"/profile/edit"} element={<Profile/>} />
        <Route path={"/messages"} element={<Messages/>} />

        <Route path={"/ticket"} element={<Ticket/>} />
        <Route path={"/ticket/:ticket_id"} element={<TicketView/>} />
        <Route path={"/payments"} element={<Payments/>} />
        <Route path={"/bookmark"} element={<Bookmark/>} />
      </Routes>
  );
}

export default App;
